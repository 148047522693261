/* eslint-disable max-len */

// Imports
import React, { useState, useEffect, useContext } from 'react';
import { myContext } from '../components/Provider';

// Components
import Layout from '../components/Layout';
import ProductContainer from '../components/ProductContainer';

// ======== Component ========
const CategoryPageTemplate = props => {
	const {
		pageContext,
	} = props;
	// Grab React context value and store it in state
	const contextValue = useContext(myContext);
	const [productData, setProductData] = useState(null);
	const [tags, setTags] = useState(null);

	// Filter the data by category
	useEffect(() => {
		if (!contextValue) return;
		let filteredProducts = '';
		// Filter the products per the category -> filter the tags per what products exist on page -> set to state
		if (pageContext.categoryName === 'All') {
			filteredProducts = contextValue.data.data;
		} else {
			filteredProducts = contextValue.data.data
				.filter(obj => {
					return obj.uk_product_category.some(categoryName => categoryName === pageContext.categoryName);
					// for (let i = 0; i <= obj.uk_product_category.length; i++) {
					// 	if (pageContext.categoryName === obj.uk_product_category[i]) {
					// 		return pageContext.categoryName === obj.uk_product_category[i];
					// 	}
					// }
				})
				.sort((a, b) => {
					let isAvailableA = 0;
					let isAvailableB = 0;

					if (a.uk_in_stock[0].includes('In stock')) isAvailableA = 1;
					if (b.uk_in_stock[0].includes('In stock')) isAvailableB = 1;

					return isAvailableB - isAvailableA;
				});
		}
		const flattenedTags = [...new Set(filteredProducts.map(obj => { return obj.tags; }).flat())];
		const filteredTags = contextValue.tags.filter(obj => { return flattenedTags.indexOf(obj.id) > -1; });

		setTags(filteredTags);
		setProductData(filteredProducts);
	}, [contextValue]); // eslint-disable-line

	return (
		<Layout title={`${ pageContext.categoryName } | Balanced Body UK`}>
			{productData !== null && (
				<ProductContainer
					productData={productData}
					tags={tags}
					categoryName={pageContext.categoryName}
				/>
			)}
		</Layout>
	);
};

export default CategoryPageTemplate;
